export enum UserType {
  CLINICIAN = "App\\Models\\Clinician",
  PATIENT = "App\\Models\\Patient",
  SUPPORT = "App\\Models\\SupportStaff",
}
export interface MDFile {
  path: string;
  name: string;
  mime_type: string;
  url: string;
  url_thumbnail: string | null;
  file_id: string;
}
