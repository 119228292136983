import { ConditionTypes } from "@store/../@types/condition";

import MalariaProphylaxis from "../../src/img/orders/Pill Bottle - Color=darkblue - Size=564pt.png";
import NauseaPrevention from "../../src/img/orders/Pill Bottle - Color=gray - Size=564pt.png";
import UrinaryTractInfection from "../../src/img/orders/Pill Bottle - Color=lavender - Size=564pt.png";
import MotionSickness from "../../src/img/orders/Pill Bottle - Color=lightblue - Size=564pt - Patch=True.png";
import RunwayPlus from "../../src/img/orders/Pill Bottle - Color=lightblue - Size=564pt.png";
import AltitudeSickness from "../../src/img/orders/Pill Bottle - Color=lightgreen - Size=564pt.png";
import VaginalYeastInfection from "../../src/img/orders/Pill Bottle - Color=pink - Size=564pt.png";
import TyphoidFever from "../../src/img/orders/Pill Bottle - Color=salmon - Size=564pt.png";
import TravelDiarrhea from "../../src/img/orders/Pill Bottle - Color=teal - Size=564pt.png";
import Insomnia from "../../src/img/orders/Pill Bottle - Color=yellow - Size=564pt.png";

const productImages = {
  [ConditionTypes.ALTITUDE_CONSULTATION]: AltitudeSickness,
  [ConditionTypes.MALARIA_CONSULTATION]: MalariaProphylaxis,
  [ConditionTypes.MOTION_CONSULTATION]: MotionSickness,
  [ConditionTypes.NAUSEA_CONSULTATION]: NauseaPrevention,
  [ConditionTypes.RUNWAY_PLUS]: RunwayPlus,
  [ConditionTypes.INSOMNIA_CONSULTATION]: Insomnia,
  [ConditionTypes.DIARRHEA_CONSULTATION]: TravelDiarrhea,
  [ConditionTypes.TYPHOID_FEVER]: TyphoidFever,
  [ConditionTypes.URINARY_TRACT_INFECTION]: UrinaryTractInfection,
  [ConditionTypes.VAGINAL_YEAST_INFECTION]: VaginalYeastInfection,
};

export const getProductImage = (condition: ConditionTypes) => {
  return productImages[condition] || productImages[ConditionTypes.RUNWAY_PLUS];
};
