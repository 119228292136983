import "./CheckoutPage.scss";

import Cookies from "js-cookie";
import moment from "moment-business-days-sk";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import InfoSlider from "@components/elements/InfoSlider";
import { PageConsultation } from "@components/layouts/PageConsultation";
import PaymentMethodManager, { PaymentMethodManagerView } from "@components/PaymentMethodManager";
import { useTitle } from "@hooks/useTitle";
import {
  AlertBanner,
  AlertBannerMessage,
  Option,
  Text,
  Title,
} from "@runwayhealth/runway-components-react";
import { Case, ShippingMethods } from "@store/../@types/case";
import { ConditionTypes } from "@store/../@types/condition";
import { ErrorEvents, PricesState, RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import * as casesActions from "@store/cases/casesActions";
import * as casesSlice from "@store/cases/casesSlice";
import { GET_AFFILIATE_PROFILE, GET_PROMO_CODES } from "@store/user/userActions";

import { coupons } from "../../../api";
import Loader from "../../../img/Loader.svg";
// Visit Price ID (Stripe reference for visit fee.)
import { VISIT_FEE_ID } from "../../../utils/constantsEnv";
import Header from "../../elements/Header";
import CloseX from "./img/CloseX.svg";
import YesMark from "./img/YesMark.svg";
import { ShippingBody, ShippingContainer } from "./ShippingMethod";

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Context entities.
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const userCases = useSelector<RootState, RootEntity<Case>[]>((state) => state.case.cases.data);
  // Services prices.
  const prices = useSelector<RootState, PricesState>((state) => state.price);

  //Promo codes & discounts.
  const [promo, setPromo] = useState({
    discount: 0,
    havePromo: false,
    code: "",
    id: "",
  });
  // Checkout summary.
  const [totalMedicationPrice] = useState<number>(
    newCase.data.conditions.reduce((sum, condition) => sum + (condition?.price || 0), 0)
  );

  const [includesTyphoid] = useState<boolean>(
    newCase.data.conditions
      .map((condition) => condition.product.name)
      .includes(ConditionTypes.TYPHOID_FEVER)
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shippingPriceAmount, setShippingPriceAmount] = useState(0);

  const [isReferred, setIsReferred] = useState(false);

  const [infoSliderIsOpen, setInfoSliderIsOpen] = useState(false);

  const addDaysAndFormat = (date: Date, days: number) => {
    const dateWithDays = moment(date).businessAdd(days).toDate();
    return dateWithDays.toLocaleString("en-us", {
      month: "short",
      day: "numeric",
    });
  };

  // Delivery dates
  const today = new Date();
  const superfast = addDaysAndFormat(today, 2);
  const fastest = addDaysAndFormat(today, 3);
  const slowest = addDaysAndFormat(today, 8);
  const neutral = addDaysAndFormat(today, 5);

  const [errorText, setErrorText] = useState("");

  useTitle("Checkout");

  // Hooks.

  // Find offers on component mount.
  useEffect(() => {
    // Get referral link cookie.
    const referralCookie = JSON.parse(Cookies.get("rewardful.referral") ?? "{}");

    // Find offers
    if (referralCookie.coupon) {
      // Fetchs affiliate profile.
      if (!user.data.affiliateProfile) {
        dispatch({
          type: GET_AFFILIATE_PROFILE,
        });
      }
    } else {
      // Find rewards.
      dispatch({
        type: GET_PROMO_CODES,
      });
    }
  }, []);

  // Checks errors
  useEffect(() => {
    if (newCase.error.event === ErrorEvents.ACCESS_DENIED) {
      history.push("/login");
    }
  }, [newCase.error]);

  // Set shipping prices.
  useEffect(() => {
    if (user.data.pharmacy_id) {
      setShipping(null);
    }

    if (includesTyphoid) {
      setShipping(ShippingMethods.UPS_NEXT_DAY_AIR);
    }

    // Set shipping price amount.
    switch (newCase.data.shipping_method) {
      case ShippingMethods.USPS_PRIORITY:
        setShippingPriceAmount(prices.shipping.data[0]?.fixed_amount?.amount ?? 0);
        break;
      case ShippingMethods.UPS_NEXT_DAY_AIR:
        setShippingPriceAmount(prices.shipping.data[1]?.fixed_amount?.amount ?? 0);
        break;
    }
  }, [prices, newCase.data.shipping_method]);

  // Validates referrals.
  useEffect(() => {
    if (user.data.affiliateProfile) {
      // Get referral link cookie.
      const referralCookie = JSON.parse(Cookies.get("rewardful.referral") ?? "{}");

      // If there is a referral cookie.
      if (referralCookie.coupon) {
        // Validate referral.
        let referralIsValid = false;
        // If referral token is equals to the cookie token, then is invalid.
        if (referralCookie.affiliate?.token !== user.data.affiliateProfile.links[0].token) {
          // Valid referral has no previous purchases.
          const userPaidCases = userCases.filter((case_: RootEntity<Case>) => case_.data.caseId);
          if (userPaidCases.length === 0) {
            referralIsValid = true;
          }
        }

        if (!referralIsValid) {
          // Remove the cookie.
          Cookies.remove("rewardful.referral");
          return;
        }
        coupons.couponGet(`/?id=${referralCookie.coupon.id}`).then((coupon) => {
          const discountAmount = prices.case.data.unit_amount * (coupon.data.percent_off / 100);
          setPromo((prev) => ({
            ...prev,
            discount: discountAmount,
          }));
          setIsReferred(true);
        });
      }
    }
  }, [user.data.affiliateProfile]);

  useEffect(() => {
    const promocodes = user.data?.promocodes;
    if (promocodes && promocodes.length > 0) {
      // Find active promocodes for user.
      const activePromocodes = user.data.promocodes
        .filter((promocode) => promocode.active === true)
        .map((promocodeObject) => promocodeObject.code);

      if (activePromocodes.length > 0) {
        const code = activePromocodes[0];
        // Redundanly apply promo code to avoid
        // async state transition
        setPromo((prev) => ({ ...prev, code, havePromo: true }));
        applyPromoCode(code);
      }
    }
  }, [user.data.promocodes]);

  const setShipping = (shipping: ShippingMethods | null) => {
    dispatch(casesSlice.updateShippingMethod(shipping));
    if (newCase.data.id) {
      dispatch({
        type: casesActions.UPDATE_CASE,
        caseId: newCase.data.id,
        updateBody: {
          shipping_method: shipping,
        },
      });
    }
  };

  const applyPromoCode = async (value?: string) => {
    // Retrieve for the promoCode.
    const promocode = value ?? promo.code;
    if (!promocode.length) setErrorText("Please enter your discount code");
    if (promocode !== "") {
      // Get promo code
      const codesResult = await coupons.couponGet(`/promo_code?code=${promocode}&active=true`);
      const promoCode = codesResult.data[0];

      // If the promo code is for a customer, but user does not have promocodes
      // it means it doesn't belong to this user, so is **exclusive**
      const promoCodeIsExclusive = promoCode?.customer && user.data.promocodes.length === 0;
      // Validate the promo code
      const promoCodeIsValid =
        promoCode &&
        promoCode.coupon.metadata.price === VISIT_FEE_ID &&
        promoCode.coupon.valid &&
        !promoCodeIsExclusive;

      if (!promoCodeIsValid) {
        setErrorText("The code is invalid. Please enter a valid code.");
        return;
      }

      const discountAmount =
        promoCode.coupon.amount_off ??
        prices.case.data.unit_amount * (promoCode.coupon.percent_off / 100);

      setPromo((prev) => ({
        ...prev,
        id: promoCode.id,
        discount: discountAmount,
      }));
      setIsModalVisible(true);
      setErrorText("");
      // Save promocode in local storage for analytics purposes.
      localStorage.setItem("promoCode", promocode);
    }
  };

  const removePromoCode = async () => {
    setPromo((prev) => ({ ...prev, id: "", code: "", discount: 0 }));
  };

  // Should be handled properly with TypeScript.
  const handleCode = (e: any) => {
    const { value } = e.target;
    setPromo((prev) => ({ ...prev, code: value }));
  };

  const formattedPrice = (value: number, decimals = 2) => (value / 100).toFixed(decimals);

  const typhoidInfo = (
    <>
      <h2>Why we care about a faster shipping method?</h2>
      <hr />
      <p>
        The Typhoid Vaccine (Oral) requires refrigeration during storage and shipment. Express
        shipping, in specialized packaging, ensures the vaccine remains stable during transit.
      </p>
    </>
  );

  return (
    <>
      <Header progress={97} />
      <div className="checkout-container">
        <PageConsultation.Header>
          <Title as="p" size="md-bold">
            Checkout
          </Title>
          <Title size="giant">Enter your payment details</Title>
          {user.data?.partnerTx && (
            <AlertBanner isRounded>
              <AlertBanner.Message>
                We'll collect your payment details to pay for your medications once prescribed, if
                any.
              </AlertBanner.Message>
            </AlertBanner>
          )}
        </PageConsultation.Header>

        <div className="fee-container">
          <div className="medication-fees">
            {newCase.data.conditions.length > 0 && (
              <Title as="h2" size="md-bold">
                Medication Fees
              </Title>
            )}
            {newCase.data.conditions.map((condition, index) => {
              return (
                <div className="fees-consultation" key={index}>
                  <Text as="span">{condition.product.name}</Text>
                  <Title as="span" size="md-bold" noMarginBottom>
                    ${formattedPrice(condition?.price || 0)}
                  </Title>
                </div>
              );
            })}
            {/* Not custom pharmacy */}
            {user.data.pharmacy_id === "" || user.data.pharmacy_id === null ? (
              <>
                <div className="shipping-address">
                  <Title as="h2" size="md-bold">
                    Shipping Address
                  </Title>

                  <div className="shipping-info">
                    <Text>{`${user.data.first_name} ${user.data.last_name}`}</Text>
                    <Text>
                      {`${user.data.shipping_address.address || user.data.address.address},
                  ${user.data.shipping_address.city_name || user.data.address.city_name},
                  ${user.data.shipping_address.state_name || user.data.address.state_name},
                  ${user.data.shipping_address.zip_code || user.data.address.zip_code}`}
                    </Text>
                  </div>
                </div>
                <div className="medicine-fee">
                  <Title as="h2" size="md-bold">
                    Shipping Method
                  </Title>
                  {newCase.data.conditions.length === 0 && (
                    <p>In case you get a prescription, please choose a shipping method:</p>
                  )}
                  <ShippingContainer>
                    <Option
                      id="standard"
                      checked={newCase.data.shipping_method === null}
                      value="standard"
                      onClick={() => {
                        setShipping(null);
                        setShippingPriceAmount(0);
                      }}
                      isDisabled={includesTyphoid}
                    >
                      <ShippingBody>
                        <div className="shipping-body-info">
                          <div>
                            <Text as="span" size="sm" noMarginBottom>
                              Estimated arrival between
                            </Text>
                            <Title as="h3" size="sm" noMarginBottom>
                              {`${neutral} - ${slowest}`}
                            </Title>
                          </div>
                          <div>
                            <Title as="h3" size="tiny" noMarginBottom>
                              Standard
                            </Title>
                            <Text as="span" size="sm" noMarginBottom>
                              5-8 business days
                            </Text>
                          </div>
                        </div>
                        <div className="shipping-body-price">
                          <Title as="h3" size="md-bold" className="free-price" noMarginBottom>
                            FREE
                          </Title>
                        </div>
                      </ShippingBody>
                    </Option>

                    <Option
                      id="priority"
                      checked={newCase.data.shipping_method === ShippingMethods.USPS_PRIORITY}
                      value="priority"
                      onClick={() => {
                        setShipping(ShippingMethods.USPS_PRIORITY);
                        setShippingPriceAmount(prices.shipping.data[0]?.fixed_amount?.amount ?? 0);
                      }}
                      isDisabled={includesTyphoid}
                    >
                      <ShippingBody>
                        <div className="shipping-body-info">
                          <div>
                            <Text as="span" size="sm" noMarginBottom>
                              Estimated arrival between
                            </Text>
                            <Title as="h3" size="sm" noMarginBottom>
                              {`${fastest} - ${neutral}`}
                            </Title>
                          </div>
                          <div>
                            <Title as="h3" size="tiny" noMarginBottom>
                              Priority
                            </Title>
                            <Text as="span" size="sm" noMarginBottom>
                              3-5 business days
                            </Text>
                          </div>
                        </div>
                        <div className="shipping-body-price">
                          <Title as="h3" size="md-bold" noMarginBottom>
                            $
                            {prices.shipping.data.length > 0
                              ? formattedPrice(
                                  prices.shipping.data[0]?.fixed_amount?.amount || 0,
                                  2
                                )
                              : 0}
                          </Title>
                        </div>
                      </ShippingBody>
                    </Option>
                    <Option
                      id="express"
                      checked={newCase.data.shipping_method === ShippingMethods.UPS_NEXT_DAY_AIR}
                      value="express"
                      onClick={() => {
                        setShipping(ShippingMethods.UPS_NEXT_DAY_AIR);
                        setShippingPriceAmount(prices.shipping.data[1]?.fixed_amount?.amount ?? 0);
                      }}
                    >
                      <ShippingBody>
                        <div className="shipping-body-info">
                          <div>
                            <Text as="span" size="sm" noMarginBottom>
                              Estimated arrival between
                            </Text>
                            <Title as="h3" size="sm" noMarginBottom>
                              {`${superfast} - ${fastest}`}
                            </Title>
                          </div>
                          <div>
                            <Title as="h3" size="tiny" noMarginBottom>
                              Express
                            </Title>
                            <Text as="span" size="sm" noMarginBottom>
                              2-3 business days
                            </Text>
                          </div>
                        </div>
                        <div className="shipping-body-price">
                          <Title as="h3" size="md-bold" noMarginBottom>
                            $
                            {prices.shipping.data.length > 0
                              ? formattedPrice(
                                  prices.shipping.data[1]?.fixed_amount?.amount || 0,
                                  2
                                )
                              : 0}
                          </Title>
                        </div>
                      </ShippingBody>
                    </Option>
                  </ShippingContainer>
                </div>
              </>
            ) : null}
            {includesTyphoid && (
              <AlertBanner variant="warning">
                <AlertBannerMessage>
                  The Typhoid Vaccine (Oral) requires Express Shipping. Your shipping will
                  automatically be set to accommodate this requirement.
                  <div className="referral-form-link" onClick={() => setInfoSliderIsOpen(true)}>
                    Learn more.
                  </div>
                </AlertBannerMessage>
              </AlertBanner>
            )}
            <div className="due">
              <Title as="h3" size="xs" className="info">
                Due Today
              </Title>

              {!user.isPending ? (
                <>
                  <div className="visit-fee">
                    <div className="due-price">
                      <Text className="fee-title">Visit Fee:</Text>
                    </div>
                    <div className="price-container">
                      <div className="price-container-row">
                        {user.data?.partnerTx ? (
                          <Title as="span" size="md-bold" className="fee-price">
                            $0
                          </Title>
                        ) : (
                          <>
                            {isReferred && (
                              <span className="referral-discount-title">
                                Referral Link Discount Applied
                              </span>
                            )}
                            <Title as="span" size="md-bold" className="fee-price">
                              ${formattedPrice(prices.case.data.unit_amount - promo.discount)}
                            </Title>
                          </>
                        )}
                        {promo.id || isReferred ? (
                          <span className="fee-price promo">
                            {formattedPrice(prices.case.data.unit_amount)}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <AlertBanner variant="info">
                    <AlertBannerMessage>$0 if we can't help.</AlertBannerMessage>
                  </AlertBanner>
                </>
              ) : (
                <>
                  {" "}
                  <img className="loader-img" src={Loader} />
                </>
              )}
              <div className="medicine-fee">
                <Title as="h3" size="xs">
                  Due only if prescribed
                </Title>
                <div className="due-price-info">
                  <Text className="fee-title">Medicine Price:</Text>
                  <Title as="div" size="md-bold" className="fee-price">
                    ${formattedPrice(totalMedicationPrice)}
                  </Title>
                </div>
                <div className="due-price-info">
                  <Text className="fee-title">Shipping:</Text>
                  <Title as="div" size="md-bold" className="fee-price">
                    ${formattedPrice(shippingPriceAmount)}
                  </Title>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isReferred && !promo.havePromo && !user.data?.partnerTx && (
          <div
            onClick={() => setPromo((prev) => ({ ...prev, havePromo: true }))}
            className="discount"
          >
            Have a discount code ?
          </div>
        )}
        {promo.havePromo ? (
          promo.id !== "" ? (
            <div className="discount-wrapper">
              <p className="promo-question">Have a discount code?</p>
              <div className="discount-promocode-container active">
                <p className="applyedCode">{promo.code}</p>
                <p onClick={() => removePromoCode()} className="apply-code">
                  Remove code
                </p>
              </div>
              <div className={isModalVisible ? "modal active" : "modal"}>
                <img src={YesMark} className="checked-mark" alt="YesMark button" />
                <span className="modal-message">Your discount code was successfully applied.</span>
                <img
                  onClick={() => setIsModalVisible(false)}
                  src={CloseX}
                  className="close-mark"
                  alt="CloseX button"
                />
              </div>
            </div>
          ) : (
            <div className="discount-wrapper">
              <p className="promo-question">Have a discount code?</p>
              <div className="discount-promocode-container">
                <div className="discount-promocode-input">
                  <input
                    id="password"
                    className="promo-input"
                    onChange={handleCode}
                    type="text"
                    placeholder="Discount code"
                  />
                  <p onClick={() => applyPromoCode()} className="apply-code">
                    Apply code
                  </p>
                </div>
                <p className="error-text">{errorText}</p>
              </div>
            </div>
          )
        ) : null}
      </div>
      <div className="payment-container">
        <PaymentMethodManager
          user={user}
          view={PaymentMethodManagerView.CHECKOUT}
          promoCodeId={promo.id}
        />
      </div>
      <InfoSlider
        open={infoSliderIsOpen}
        close={() => setInfoSliderIsOpen(false)}
        content={typhoidInfo}
      />
    </>
  );
};

export default CheckoutPage;
