import { push } from "connected-react-router";
import { call, delay, put, retry, take, takeEvery } from "redux-saga/effects";

import * as Sentry from "@sentry/react";
import { PaymentManagerSetup, PaymentMethod } from "@store/../@types/billing";
import { Case } from "@store/../@types/case";
import { ErrorEvents, selectState } from "@store/../@types/state";
import { PASSWORD_RESET_TOKEN_KEY, User } from "@store/../@types/user";
import * as casesActions from "@store/cases/casesActions";
import { WEBSOCKET_SEND, WebSocketSend } from "@store/socket/socketActions";
import { SocketEvent, SocketEvents } from "@store/socket/socketChannel";
import { setSocketConnection } from "@store/socket/socketSaga";
import { toastError, toastSuccess } from "@utils/utils";

import { auth, user as userRoutes } from "../../api";
import * as casesSlice from "../cases/casesSlice";
import { clearMessages } from "../messages/messagesSlice";
import * as pricesSlice from "../prices/pricesSlice";
import * as questionsSlice from "../questions/questionsSlice";
import { errorHandler } from "../ui/uiSlice";
import * as userActions from "./userActions";
import {
  cleanUserError,
  clearUser,
  shippingAddressUpdate,
  userError,
  userMutationSuccess,
  userPending,
} from "./userSlice";

function* login({ email, password }: userActions.Login) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const user: User = yield selectState<User>((state) => state.user.data);
    const newCase: Case = yield selectState<Case>((state) => state.case.newCase.data);
    const { data } = yield call(() =>
      auth.authPost("login", {
        email,
        password,
      })
    );
    localStorage.setItem("token", data.token);
    yield put(
      userMutationSuccess({
        ...data.user,
        partnerTx: user?.partnerTx,
      })
    );
    // We populate user cases on login.
    yield put(casesSlice.getCasesSuccess(data.cases));
    // Sync up cases with MDI during login.
    yield put({ type: casesActions.REFRESH_CASES, userId: data.user.id });
    yield put(shippingAddressUpdate(data.user.address));
    yield put(errorHandler(""));

    const newCaseId = newCase.id ?? 0;

    if (newCaseId > 0) {
      // Assing new case to user.
      yield put({
        type: casesActions.UPDATE_CASE,
        caseId: newCase.id,
        updateBody: { UserId: data.user.id },
      });
    }

    Sentry.setUser({ email: data.user.email });
    // Initialize web socket connection.
    yield call(setSocketConnection, data.token);
  } catch (e) {
    const exception: any = e;
    const event = exception?.response?.status === 500 ? ErrorEvents.SERVER_ERROR : undefined;
    const message = exception?.response?.data.message || event || "Unknown error.";
    yield put(
      userError({
        event,
        message,
      })
    );
  }
}
function* logout({ userId }: userActions.Logout) {
  try {
    yield put(cleanUserError());
    yield call(() => auth.authDelete("logout"));
    sessionStorage.clear();
    localStorage.removeItem("token");
    // Clear the user cases from the redux state before an app refresh.
    yield put(clearUser());
    yield put(casesSlice.clearCases());
    yield put(casesSlice.clearNewCase());
    yield put(pricesSlice.clearPrescriptionsPrices());
    yield put(questionsSlice.clearQuestions());
    yield put(clearMessages());
    // Disconnect socket.
    const event: SocketEvent<{}> = {
      eventName: SocketEvents.DISCONNECT,
      payload: {
        userId,
      },
    };
    const action: WebSocketSend<{}> = { type: WEBSOCKET_SEND, event };
    yield put(action);
  } catch (e) {
    Sentry.captureException(e);
    Sentry.captureMessage("A user is having trouble logging out.");
  }
}
function* signup({ user }: userActions.Signup) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    // Set sentry user before changing component.
    Sentry.setUser({ email: user.email });
    // Report missing address if any.
    if (user.address?.address === "") {
      Sentry.captureMessage("Missing Address Line 1 field.");
    }
    const {
      data: { user: newUser },
    } = yield call(() => userRoutes.userPost("", { user }));
    // Once registered, log in user.
    yield put({
      type: userActions.LOGIN,
      email: user.email,
      password: user.password,
    });
    // Wait for the user mutation success to continue processing the registration.
    yield take(userMutationSuccess);
    // Run patient profile creation in background.
    yield put({ type: userActions.CREATE_PATIENT_PROFILE, userId: newUser.id });
  } catch (error) {
    const exception: any = error;
    const event = exception?.response?.status === 500 ? ErrorEvents.SERVER_ERROR : undefined;
    const message =
      exception?.response?.data?.message || exception?.response?.statusText || "Unknown error.";

    if (event !== ErrorEvents.SERVER_ERROR) {
      Sentry.captureException(error);
      Sentry.captureMessage("A user is having trouble registering.");
    }

    yield put(
      userError({
        event,
        message,
      })
    );
  }
}

function* createPatientProfile({ userId }: userActions.CreatePatientProfile) {
  const user: User = yield selectState<User>((state) => state.user.data);
  try {
    if (user.address?.address === "") {
      Sentry.captureMessage("Missing Address Line 1 field.");
    }
    const { data: updatedUser } = yield retry(
      3,
      3000,
      userRoutes.userPost,
      `${userId}/patient-profile`,
      {}
    );
    yield put(
      userMutationSuccess({
        ...user,
        "patient-id": updatedUser["patient-id"],
      })
    );
  } catch (error) {
    Sentry.captureMessage(`Address here: ${JSON.stringify(user.address)}`);
    Sentry.captureException(error);
  }
}

function* restorePassword({ email }: userActions.RestorePassword) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    yield call(() =>
      auth.authPost("password/reset", {
        email,
      })
    );
    yield put(userMutationSuccess({}));
    toastSuccess("Please check your email.", "We've sent you a link to recover your password.");
    yield put(push("/login"));
  } catch (e) {
    let event: string | undefined;

    yield put(
      userError({
        event,
        message: "Could not restore password.",
      })
    );
  }
}

function* collectMarketingEmail({ email }: userActions.CollectMarketingEmail) {
  try {
    yield call(() => userRoutes.userPost("collect-marketing-email", { email }));
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* verifySecurityCode({ token }: userActions.VerifySecurityCode) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const { data } = yield call(() => auth.authPublicGet(`password/reset/?token=${token}`));
    localStorage.setItem("token", data.token);
    localStorage.setItem(PASSWORD_RESET_TOKEN_KEY, token);
    yield put(userMutationSuccess(data.user));
    yield put(push("/set-new-password"));
  } catch (e) {
    const exception: any = e;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    } else if (exception?.response?.status === 500) {
      event = ErrorEvents.SERVER_ERROR;
    }

    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* passwordUpdate({ oldPassword, newPassword, resetToken }: userActions.PasswordUpdate) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    yield call(() =>
      auth.authPut("password", {
        oldPassword,
        newPassword,
        resetToken,
      })
    );

    if (oldPassword) {
      toastSuccess("Success!", "Your password has been updated");
    }

    if (resetToken) {
      toastSuccess("Success!", "You may now login with your new password");
      yield put(push("/login"));
    }
  } catch (e) {
    toastError("Error!", "Could not update password.");
    const exception: any = e;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }

    const message = event || exception?.response?.statusText || "Unknown error.";
    yield put(
      userError({
        event,
        message: message === ErrorEvents.BAD_REQUEST ? exception?.response?.data?.error : message,
      })
    );
  }
}

function* userUpdate({ userUpdate }: userActions.UserUpdate) {
  try {
    const { data } = yield call(() => userRoutes.userPatch("profile", userUpdate));
    yield put(userMutationSuccess(data));
    toastSuccess("Success!", "Your profile has been updated");
  } catch (e) {
    const exception: any = e;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }

    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* updateShippingAddress({ address }: userActions.UpdateShippingAddress) {
  try {
    const { data } = yield call(() => userRoutes.userPatch("shipping-address", { address }));
    yield put(userMutationSuccess(data));
    toastSuccess("Success!", "Your shipping address has been updated.");
  } catch (e) {
    toastError("Error!", "Could not update shipping address.");
    const exception: any = e;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }

    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* getPaymentMethods() {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const user: User = yield selectState<User>((state) => state.user.data);
    const { data } = yield call(() => userRoutes.userGet("payment-methods"));
    yield put(userMutationSuccess({ ...user, paymentMethods: data }));
  } catch (e) {
    toastError("Error.", "Could not get payment methods.");
    const exception: any = e;
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* addPaymentMethod({ paymentMethodId, pendingCase }: userActions.AddPaymentMethod) {
  yield put(userPending());
  yield put(cleanUserError());
  try {
    const user: User = yield selectState<User>((state) => state.user.data);
    const { data } = yield call(() =>
      userRoutes.userPost("payment-method", {
        paymentMethodId,
      })
    );
    const paymentMethods: PaymentMethod[] = [...user.paymentMethods, data];
    yield put(userMutationSuccess({ ...user, paymentMethods }));

    // Set default after adding it.
    yield put({
      type: userActions.SET_DEFAULT_PAYMENT_METHOD,
      methodId: paymentMethodId,
      managerSetup: PaymentManagerSetup.ADD,
      pendingCase,
    });

    if (!pendingCase) {
      toastSuccess("Success!", "New payment method added.");
    }
  } catch (e) {
    const exception: any = e;
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* updatePaymentMethod({ paymentMethodUpdate }: userActions.UpdatePaymentMethod) {
  yield put(userPending());
  yield put(cleanUserError());
  try {
    const user: User = yield selectState<User>((state) => state.user.data);
    const { data } = yield call(() =>
      userRoutes.userPut("payment-method", { paymentMethod: paymentMethodUpdate })
    );
    const paymentMethods: PaymentMethod[] = [...user.paymentMethods];

    const updatedPaymentMethodIndex = paymentMethods.findIndex(
      (method) => method.id === paymentMethodUpdate.id
    );

    paymentMethods[updatedPaymentMethodIndex] = data;

    yield put(userMutationSuccess({ ...user, paymentMethods }));

    // Set default after adding it.
    yield put({
      type: userActions.SET_DEFAULT_PAYMENT_METHOD,
      methodId: paymentMethodUpdate.id,
      managerSetup: PaymentManagerSetup.EDIT,
    });
    toastSuccess("Success!", "Payment method updated.");
  } catch (e) {
    const exception: any = e;
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* setDefaultPaymentMethod({
  methodId,
  managerSetup,
  pendingCase,
}: userActions.SetDefaultPaymentMethod) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const user: User = yield selectState<User>((state) => state.user.data);
    yield call(() => userRoutes.userPatch(`payment-method/${methodId}/default`));
    yield put(userMutationSuccess({ ...user, defaultPaymentMethod: methodId }));
    if (managerSetup === PaymentManagerSetup.SET_DEFAULT && !pendingCase) {
      toastSuccess("Success!", "Preferred payment method updated.");
    }
  } catch (e) {
    const exception: any = e;
    toastError("Error.", "Could not set payment method as default.");
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* deletePaymentMethod({ methodId }: userActions.DeletePaymentMethod) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const user: User = yield selectState<User>((state) => state.user.data);
    yield call(() => userRoutes.userDelete(`payment-method/${methodId}`));
    // Splice payment method.
    const paymentMethods = user.paymentMethods.filter((method) => method.id !== methodId);
    yield put(userMutationSuccess({ ...user, paymentMethods }));
    toastSuccess("Success!", "Payment method removed.");
  } catch (e) {
    const exception: any = e;
    toastError("Error.", "Could not delete payment method.");
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* sendTravelGroupEmail({ emails }: userActions.SendTravelGroupEmail) {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    yield call(() =>
      userRoutes.userPost("referrals", {
        referrals: {
          emails,
          type: "travel_group",
        },
      })
    );
    const user: User = yield selectState<User>((state) => state.user.data);
    yield put(userMutationSuccess({ ...user }));
  } catch (e) {
    const exception: any = e;
    toastError("Error.", "Could not refer travel group.");
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    } else if (exception?.message === ErrorEvents.ERR_NETWORK) {
      event = ErrorEvents.ERR_NETWORK;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* getAffiliateProfile() {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    // This delay is aimed at waiting for affiliate profile creation.
    yield delay(2000);
    // Which happens synchronously in external services.
    const { data } = yield call(() => userRoutes.userGet("affiliate-profile"));
    const user: User = yield selectState<User>((state) => state.user.data);
    yield put(userMutationSuccess({ ...user, affiliateProfile: data }));
  } catch (e) {
    const exception: any = e;
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    } else if (exception?.message === ErrorEvents.ERR_NETWORK) {
      event = ErrorEvents.ERR_NETWORK;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* getPromoCodes() {
  try {
    yield put(userPending());
    yield put(cleanUserError());
    const { data } = yield call(() => userRoutes.userGet("customer/promocodes"));
    const user: User = yield selectState<User>((state) => state.user.data);
    yield put(
      userMutationSuccess({
        ...user,
        promocodes: data,
      })
    );
  } catch (e) {
    const exception: any = e;
    toastError("Error.", "Could not get affiliate link.");
    let event: string | undefined;
    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    } else if (exception?.message === ErrorEvents.ERR_NETWORK) {
      event = ErrorEvents.ERR_NETWORK;
    }
    yield put(
      userError({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

export default function* userSaga() {
  yield takeEvery(userActions.LOGIN, login);
  yield takeEvery(userActions.LOGOUT, logout);
  yield takeEvery(userActions.SIGNUP, signup);
  yield takeEvery(userActions.CREATE_PATIENT_PROFILE, createPatientProfile);
  yield takeEvery(userActions.GET_PAYMENT_METHODS, getPaymentMethods);
  yield takeEvery(userActions.ADD_PAYMENT_METHOD, addPaymentMethod);
  yield takeEvery(userActions.UPDATE_PAYMENT_METHOD, updatePaymentMethod);
  yield takeEvery(userActions.DELETE_PAYMENT_METHOD, deletePaymentMethod);
  yield takeEvery(userActions.SET_DEFAULT_PAYMENT_METHOD, setDefaultPaymentMethod);
  yield takeEvery(userActions.USER_UPDATE, userUpdate);
  yield takeEvery(userActions.PASSWORD_UPDATE, passwordUpdate);
  yield takeEvery(userActions.UPDATE_SHIPPING_ADDRESS, updateShippingAddress);
  yield takeEvery(userActions.RESTORE_PASSWORD, restorePassword);
  yield takeEvery(userActions.VERIFY_SECURITY_CODE, verifySecurityCode);
  yield takeEvery(userActions.COLLECT_MARKETING_EMAIL, collectMarketingEmail);
  yield takeEvery(userActions.SEND_TRAVEL_GROUP_EMAIL, sendTravelGroupEmail);
  yield takeEvery(userActions.GET_AFFILIATE_PROFILE, getAffiliateProfile);
  yield takeEvery(userActions.GET_PROMO_CODES, getPromoCodes);
}
