import { user } from "../api";

const useMsgFile = async (file: File, fileKey: string) => {
  const form = new FormData();
  form.append(fileKey, file);

  try {
    const uploadedFile = await user.userPost("message", form);
    return uploadedFile.data;
  } catch (error) {
    console.error(error);
  }
};

export default useMsgFile;
