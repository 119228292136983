import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import ShortInput from "../../elements/ShortInput";
import { userUpdate } from "../../../store/user/userSlice";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title, Feedback, AlertBanner } from "@runwayhealth/runway-components-react";
import "./TakeDate.scss";

const TakeDate = () => {
  const currentYear = new Date().getFullYear();
  const milliSecondsInAyear = 31536000000;
  const [date, setDate] = useState({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "", 7: "", 8: "" });
  const [enabled, setEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [parentConsent, setParentConsent] = useState(false);
  const dateOfBirth = useSelector((state) => state.user.data.date_of_birth);
  const [formattedChosenDate, setFormattedChosenDate] = useState({
    month: "",
    day: "",
    year: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("Date of Birth");

  const inputsShort = [
    { placeholder: "M", name: "field-1-birth", id: "birth-1", position: 1 },
    { placeholder: "M", name: "field-2-birth", id: "birth-2", position: 2 },
    { placeholder: "D", name: "field-3-birth", id: "birth-3", position: 3 },
    { placeholder: "D", name: "field-4-birth", id: "birth-4", position: 4 },
    { placeholder: "Y", name: "field-5-birth", id: "birth-5", position: 5 },
    { placeholder: "Y", name: "field-6-birth", id: "birth-6", position: 6 },
    { placeholder: "Y", name: "field-7-birth", id: "birth-7", position: 7 },
    { placeholder: "Y", name: "field-8-birth", id: "birth-8", position: 8 },
  ];

  // Observes date state.
  useEffect(() => {
    const birthDate = new Date(dateOfBirth);
    const userAge = calculateAge(birthDate);

    // If user's age is greater than 13 on component load it means date of birth has been already provided.
    // We validate the provided age.
    if (userAge > 13) {
      if (userAge <= 19.9) {
        history.push("/height_weight");
      } else {
        history.push("/assigned_sex");
      }
    }
  }, []);

  const handleClick = () => {
    // Takes date input.
    const { year, month, day } = formattedChosenDate;

    // Validate date input.
    if (dateIsValid()) {
      // Validate age greater than 12 years old.
      const stringifyDate = `${year}-${month}-${day}`;
      const birthDate = new Date(stringifyDate);
      const usersAge = calculateAge(birthDate);

      if (usersAge < 13) {
        setError("You must be at least 13 years old");
        return;
      }
      dispatch(userUpdate({ name: "date_of_birth", value: birthDate }));

      if (usersAge <= 19.9) {
        history.push("/height_weight");
      } else {
        history.push("/assigned_sex");
      }
    } else {
      setError("Please enter your correct date of birth");
    }
  };

  const dateIsValid = () => {
    const { year, month, day } = formattedChosenDate;
    if (
      currentYear < +year ||
      +month < 1 ||
      +month > 12 ||
      +day < 1 ||
      +day > 31 ||
      currentYear - +year > 122
    ) {
      return false;
    }
    return true;
  };

  const validDate = Object.values(date).every((el) => !!el === true);

  const validateMinor = (chosenYear) => {
    // If minor, set parent consent to true.
    if (currentYear - +chosenYear > 12 && currentYear - +chosenYear < 18) {
      setParentConsent(true);
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && handleClick();
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const differenceInMilliSeconds = today.getTime() - birthDate.getTime();
    const usersAge = differenceInMilliSeconds / milliSecondsInAyear;
    return usersAge;
  };

  // Observes date state.
  useEffect(() => {
    setParentConsent(false);
    // Format chosen date.
    const objToArr = Object.values({ ...date });
    const year = objToArr.slice(4).join("");
    setFormattedChosenDate((prevState) => ({
      ...prevState,
      month: objToArr.slice(0, 2).join(""),
      day: objToArr.slice(2, 4).join(""),
      year,
    }));
    setEnabled(validDate);

    if (validDate) {
      validateMinor(year);
    }
  }, [date]);

  return (
    <>
      <Header progress={2} />
      <PageConsultation onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Next up, we need your date of birth.</Title>
          <Text size="md">Fill the field below</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="date-inputs">
            <div className="short-inputs-container">
              {inputsShort.map((item, index) => (
                <div key={`date-input-${index}`} className={"date-input-map"}>
                  <ShortInput
                    id={item.id}
                    position={item.position}
                    placeholder={item.placeholder}
                    date={date}
                    setDate={setDate}
                    setError={setError}
                  />
                  {item.position === 2 || item.position === 4 ? "-" : null}
                </div>
              ))}
            </div>
            {error && (
              <Feedback isInvalid size="sm" className="rw-page-consultation-feedback-message">
                {error}
              </Feedback>
            )}
          </div>
          {parentConsent && (
            <div className="row justify-content-center">
              <div className="col-md-8">
                <AlertBanner variant="info" className="warning-banner" isRounded>
                  <AlertBanner.Message>
                    Parental consent will be required to complete your consultation.
                  </AlertBanner.Message>
                </AlertBanner>
              </div>
            </div>
          )}
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={handleClick}
          disabled={!enabled}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default TakeDate;
