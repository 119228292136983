import "./notice.scss";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

import { ConditionTypes } from "@store/../@types/condition";
import { getProductImage } from "@utils/productImageManager";

// import OrderImg from "../../../../../../../../img/orders/runwayhealthpill_3 4.png";

interface IProps {
  caseId?: string | null;
}
const RunwayPlusNotice = ({ caseId }: IProps) => {
  return (
    <Col xs={12}>
      <Container>
        <Row>
          <Col xs={4} sm={4} md={2} lg={2}>
            <div className="treatment-order">
              <img src={getProductImage(ConditionTypes.RUNWAY_PLUS)} alt="Runway Plus" />
            </div>
          </Col>
          <Col className="centered-text">
            <h3 className="order-name">Runway+</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p>
              Thank you for purchasing <b>Runway+</b>. You may message your physician for care. If
              your physician prescribes any medications, they will display here.
            </p>
            <p>
              {caseId ? (
                <Link to={`/dashboard/messages/${caseId}`}>
                  <b>
                    <u>Contact my clinician now.</u>
                  </b>
                </Link>
              ) : null}
            </p>
            <p className="info">
              This notice may disappear once a prescription is written, but access to your physician
              remains available through your messages portal.
            </p>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default RunwayPlusNotice;
