import { ConditionTypes } from "@store/../@types/condition";

import { user } from "../api";

const useConfirmIdRoute = async (driversLicense: string, userId: string): Promise<string> => {
  const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
  if (driversLicense === "") {
    return "id";
  } else {
    try {
      const photoIdFile = await user.userGet(`${userId}/photo-id`);
      const encodedPayload = JSON.stringify({
        photoIdURL: photoIdFile?.data?.url,
        isRunwayPlus: consultations.includes(ConditionTypes.RUNWAY_PLUS),
      });
      return `/confirm-id/${window.btoa(encodedPayload ?? "")}`;
    } catch (err) {
      console.error(err);
      return "id";
    }
  }
};

export default useConfirmIdRoute;
