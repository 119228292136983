import "./ShippingPage.scss";

// smarty imports
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import usePlacesAutocomplete, {
  GeocodeResult,
  getGeocode,
  getZipCode,
} from "use-places-autocomplete";
//
import zipState from "zip-state";

import StateSelect from "@components/elements/StateSelect";
import AutocompleteInput from "@elements/AutocompleteInput";
import { Library } from "@googlemaps/js-api-loader";
import useAddressSuggestion, { AddressSuggestion } from "@hooks/useAdressSuggestions";
import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { useTitle } from "@hooks/useTitle";
import { useJsApiLoader } from "@react-google-maps/api";
import { Button } from "@runwayhealth/runway-components-react";
import * as Sentry from "@sentry/react";
import { FINALIZE_CASE } from "@store/cases/casesActions";
import { setUser } from "@store/cases/casesSlice";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { addressCompleteUpdate, addressUpdate } from "@store/user/userSlice";

import { Case } from "../../../@types/case";
import { RootEntity, RootState } from "../../../@types/state";
import { User } from "../../../@types/user";
import { MAPS_API_KEY } from "../../../utils/constantsEnv";
import Header from "../../elements/Header";
import LongInput from "../../elements/LongInput";
import MediumInput from "../../elements/MediumInput";
import PharmacyModal from "../../elements/PharmacyModal";

const states = require("../WhatState/states.json");

const ShippingPage = () => {
  const { reportPurchasedProducts: reportShippingInfo } = useDataLayer(GA4Events.ADD_SHIPPING_INFO);
  const { reportPurchasedProducts } = useDataLayer(GA4Events.BEGIN_CHECKOUT);
  const [state, setState] = useState("");
  const [shippingState, setShippingState] = useState({
    address: "",
    address2: "",
    city_name: "",
    zip_code: "",
    state_name: "",
  });

  const [errorText, setErrorText] = useState("");
  const [errorZipcode, setErrorZipcode] = useState("");
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const [address, setAddress] = useState<AddressSuggestion>();
  const [debouncedCallApi] = useState(() => debounce(useAddressSuggestion, 300));
  // const [libraries] = useState<Library[]>(["places"]);

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: MAPS_API_KEY ?? "",
  //   region: "US",
  //   libraries,
  // });

  // const {
  //   init: initPlaces,
  //   setValue: setAddress,
  //   value: address,
  //   suggestions: { data: placesArray },
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   callbackName: "initMap",
  //   debounce: 300,
  //   initOnMount: false,
  //   requestOptions: {
  //     componentRestrictions: { country: "US" },
  //   },
  // });

  // useEffect(() => {
  //   if (isLoaded) {
  //     initPlaces();
  //   }
  // }, [isLoaded]);

  // Smarty API Method
  const lookupAddress = async (query: string) => {
    setShippingState((prev) => ({
      ...prev,
      address: query,
    }));
    const addressResults = await debouncedCallApi(query);

    setSuggestions(addressResults ?? []);
  };

  const formIsValid = () => {
    const shippingAddress = shippingState;
    const validAddressForm = Object.entries(shippingAddress).some((entry) => {
      // Where 0 is the key and 1 is the value
      if (entry[0] !== "address2") {
        return entry[1] !== "";
      }
      return true;
    });
    if (!validAddressForm || state.length === 0) {
      setErrorText("Please fill all fields correctly.");
      return false;
    }
    if (!validateZipCode() || !validateAddressInput()) {
      return false;
    }
    return true;
  };

  const statusPharmacy = useSelector<RootState>((state) => state.ui.home_address_status);
  useTitle("Shipping Details");

  const handleClick = async () => {
    if (errorZipcode) return;
    if (formIsValid()) {
      const abbreviation = state;
      // Redundanly dispatch shipping state to user reducer
      dispatch(addressCompleteUpdate(shippingState));
      // Update state
      dispatch(addressUpdate({ name: "state_name", value: abbreviation }));

      // Set pharmacy mark to the clinician if Runway+
      if (newCase.data.conditions.length === 0) {
        setPharmacyQuestion();
      }

      // Set user to case if logged in.
      if (user.data.id) {
        dispatch(setUser(user.data.id));
      }

      // Set address if it was manually input
      if (!shippingState.address) {
        //dispatch(addressUpdate({ name: "address", value: address }));
        dispatch(addressUpdate({ name: "address", value: address?.street_line }));
      }

      // We set the end of the questionnaire by finalizing the case.
      dispatch({
        type: FINALIZE_CASE,
        newCase: newCase.data,
      });

      // Collect analytics.
      reportShippingInfo();
      reportPurchasedProducts();

      if (user.data.id) {
        history.push("/checkout");
      } else {
        history.push("/registration");
      }
    }
  };

  const setPharmacyQuestion = () => {
    const notCustomPharmacy = user.data.pharmacy_id === "" || user.data.pharmacy_id === null;
    if (notCustomPharmacy) {
      dispatch(
        questionsUpdate({
          question: "If prescribed, where would you like your medication shipped?",
          value: "Runway Partner Pharmacy Fulfillment",
          important: true,
          questionType: "general",
        })
      );
    } else {
      dispatch(
        questionsUpdate({
          question: "If prescribed, where would you like your medication shipped?",
          value: "I've chosen a custom pharmacy.",
          important: true,
          questionType: "general",
        })
      );
    }
  };

  const handleChangeSelect = (value: string) => {
    setErrorZipcode("");
    setErrorText("");
    setState(value);
    let name = "state_name";
    setShippingState((prev) => ({ ...prev, [name]: value }));
    dispatch(addressUpdate({ name, value }));
  };

  const handleChange = (e: any) => {
    setErrorZipcode("");
    setErrorText("");
    const { name, value } = e.target;
    setShippingState((prev) => ({ ...prev, [name]: value }));
    dispatch(addressUpdate({ name, value }));
  };

  const validateAddressInput = () => {
    const address = shippingState.address;
    const address2 = shippingState.address2;

    if (address.length >= 35 || address2.length >= 35) {
      setErrorText("Address has a limit of 35 characters.");
      return false;
    }
    return true;
  };

  const validateZipCode = () => {
    const abbreviation = state;

    if (!(shippingState.zip_code.length === 5 || shippingState.zip_code.length === 9)) {
      setErrorText("Zipcode length must be 5 or 9 symbols");
      return false;
    }

    const zipCodeState = zipState(shippingState.zip_code);

    if (abbreviation !== zipCodeState) {
      setErrorZipcode(
        "This ZIP Code does not correspond to the selected state. Please enter a valid ZIP Code"
      );
      return false;
    }
    return true;
  };

  const handleBlur = (e: any) => {
    const abbreviation = state;
    const zipCodeState = zipState(e);
    if (state && zipCodeState) {
      if (abbreviation !== zipCodeState) {
        setErrorZipcode(
          "This ZIP Code does not correspond to the selected state. Please enter a valid ZIP Code"
        );
      } else {
        setErrorZipcode("");
      }
    }
  };

  // SMARTY INTEGRATION
  const handleAddressSelection = (chosenAddress: AddressSuggestion) => {
    // Only update if the street line is defined.
    if (chosenAddress.street_line) {
      setErrorZipcode("");
      setAddress(chosenAddress);
      setSuggestions([]);
      const addressLine1 = chosenAddress.street_line;
      const city = chosenAddress.city;
      const state = chosenAddress.state;
      const zipcode = chosenAddress.zipcode;

      if (addressLine1 && city && state && zipcode) {
        setState(state);
        setShippingState((prev) => ({
          ...prev,
          address: addressLine1,
          address2: "",
          city_name: city,
          zip_code: zipcode,
          state_name: state,
        }));

        // Dispatch shipping state to user reducer
        dispatch(addressCompleteUpdate(shippingState));
      }
    }
  };
  // GOOGLE API INTEGRATION
  // const handleAddressSelection = async (address: string) => {
  //   setErrorZipcode("");
  //   clearSuggestions();

  //   let addressDetails: GeocodeResult[];
  //   try {
  //     setAddress(address, false);
  //     addressDetails = await getGeocode({ address });
  //     const addressLine1 = addressDetails[0]?.formatted_address.split(",")[0];
  //     const city = addressDetails[0]?.address_components.filter((component) =>
  //       component.types.includes("locality")
  //     )[0]?.long_name;
  //     const state = addressDetails[0]?.address_components.filter((component) =>
  //       component.types.includes("administrative_area_level_1")
  //     )[0]?.short_name;
  //     const zipCode = await getZipCode(addressDetails[0], false);

  //     if (addressLine1 && city && state && zipCode) {
  //       setState(state);
  //       setShippingState((prev) => ({
  //         ...prev,
  //         address: addressLine1,
  //         address2: "",
  //         city_name: city,
  //         zip_code: zipCode,
  //         state_name: state,
  //       }));

  //       // Dispatch shipping state to user reducer
  //       dispatch(addressCompleteUpdate(shippingState));
  //     }
  //   } catch (err) {
  //     // We log the API error in the browser.
  //     if (err !== "ZERO_RESULTS" && err !== "INVALID_REQUEST") {
  //       Sentry.captureException(err);
  //     }
  //   }
  // };

  return (
    <div>
      <Header progress={98} />
      <div className="main-container" onKeyUp={(e) => e.key === "Enter" && handleClick()}>
        {!statusPharmacy ? (
          <div className="slide-headers">
            <p className="slide-header">Checkout</p>
            <p className="slide-question">Shipping Info & Address</p>
          </div>
        ) : (
          <h1>Home address</h1>
        )}
        {!statusPharmacy ? (
          <div className="slide-main slide-shipping-main">
            <p className="slide-descript">
              Where would you like to have your medication shipped, if prescribed?
            </p>
          </div>
        ) : null}

        <div className="slide-field">
          <label className="street-address-label">STREET ADDRESS</label>
          <AutocompleteInput
            open={suggestions.length > 0}
            options={suggestions}
            //onKeyUp={(e: React.SyntheticEvent) => setAddress((e.target as HTMLInputElement).value)}
            onKeyUp={(e: React.SyntheticEvent) =>
              lookupAddress((e.target as HTMLInputElement).value)
            }
            height="30px"
            width="630px"
            fontSize="20px"
            placeholder="Street address"
            // capturedVariant={(address) => {
            //   handleAddressSelection(address.toString());
            // }}
            capturedVariant={(address) => {
              handleAddressSelection(address as AddressSuggestion);
            }}
            handleBlur={() => {}}
          />
          <LongInput
            placeholder="Apt, suite, unit, building, floor, etc."
            name={"address2"}
            onFocus={() => setErrorText("")}
            handleChange={handleChange}
            value={shippingState.address2}
            setErrorText=""
          />
          <LongInput
            placeholder="City"
            name={"city_name"}
            onFocus={() => setErrorText("")}
            handleChange={handleChange}
            value={shippingState.city_name}
            setErrorText=""
          />
          <div className="inputs">
            <StateSelect
              fontSize={"20px"}
              width={"300px"}
              name={"state_name"}
              value={state}
              options={states}
              onChange={handleChangeSelect}
              onBlur={handleBlur}
              onFocus={() => {}}
            />
            <MediumInput
              isNone={false}
              inputType={"text"}
              value={shippingState.zip_code}
              placeholder="Zipcode"
              onChange={handleChange}
              renderProps={""}
              name={"zip_code"}
              handleBlur={handleBlur}
              setErrorText={setErrorText}
              setOwnAnswer={""}
              ref={undefined}
            />
          </div>
        </div>
        <div className="buts-container shipping-bts">
          <div className={errorText || errorZipcode ? "error-message" : "error-message nonactive"}>
            <p className="message-content">{errorText || errorZipcode}</p>
          </div>
          <Button
            className="next-button"
            size="md"
            onClick={handleClick}
            disabled={errorText !== "" || errorZipcode !== ""}
            isBlock
          >
            Save and continue
          </Button>
        </div>
      </div>
      {modal && <PharmacyModal open={modal} close={setModal} />}
    </div>
  );
};

export default ShippingPage;
