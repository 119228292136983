import { useEffect, useState } from "react";

import { PhotoPrivacyFooter } from "@components/elements/PhotoPrivacyFooter";
import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import ModalWindow from "@elements/ModalWindow";
import useAmplitude from "@hooks/useAmplitude";
import { useTitle } from "@hooks/useTitle";
import IDImage from "@img/government-id.svg";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

const TakeID = () => {
  const [modal, setModal] = useState(false);
  const { logEvent } = useAmplitude();
  useTitle("Capture ID");

  const handleClick = () => {
    logEvent("ID_MODAL_OPEN");
    setModal(true);
  };

  useEffect(() => {
    logEvent("ID_START");
  }, []);

  return (
    <>
      <Header progress={95} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">Take a photo of your government issued ID</Title>
          <Text size="md">
            Your healthcare professional will use this photo to verify your identity. Please ensure
            this includes your face and your date of birth.
          </Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-upload-image">
            <div className="rw-page-consultation-upload-image-container">
              <img src={IDImage} alt="government issued ID" className="img-circle" />
            </div>
          </div>
        </PageConsultation.Body>

        <Button size="lg" iconName="ArrowRight" customWidth={440} onClick={handleClick}>
          Submit ID
        </Button>

        <PhotoPrivacyFooter />

        {modal && <ModalWindow open={modal} close={setModal} next={false} minor={false} />}
      </PageConsultation>
    </>
  );
};

export default TakeID;
