import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { RootState } from "@store/../@types/state";
import { Gender } from "@store/../@types/user";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { userUpdate } from "@store/user/userSlice";

const IsTypeGender = (gender: Gender) => gender >= 0 && gender <= 2;

const IdentifyGender = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const gender = useSelector<RootState, Gender | undefined>((state) => state.user.data.gender);

  useTitle("Sex Assigned at Birth");

  useEffect(() => {
    if (gender && IsTypeGender(gender)) {
      const nextQuestion = gender === 1 ? "/wheretogo" : "/pregnancy";
      // Move on
      history.push(nextQuestion);
    }
  }, []);

  const setAnswer = (value: string) => {
    // Determine if we should ask pregnancy / breastfeeding status
    const nextQuestion = value === "Male" ? "/wheretogo" : "/pregnancy";

    // Save answers
    dispatch(userUpdate({ name: "gender_label", value }));
    dispatch(
      questionsUpdate({
        question: "Sex at Birth?",
        value,
        questionType: "general",
      })
    );

    // Move on
    history.push(nextQuestion);
  };
  return (
    <>
      <Header progress={4} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">What was your sex assigned at birth?</Title>
          <Text size="md">For example: on your original birth certificate</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion option="" gender setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default IdentifyGender;
