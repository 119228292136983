import "./transitionComponent.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Title } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { RootEntity, RootState } from "@store/../@types/state";
import { Gender } from "@store/../@types/user";
import { UPSELL_CASE } from "@store/cases/casesActions";

import Loader from "../../img/Loader.svg";

type TransitionComponentProps = {
  transitionTask: "upsell";
  destination?: string;
};

const TransitionComponent = ({ destination, transitionTask }: TransitionComponentProps) => {
  const dispatch = useDispatch();
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const userGender = useSelector<RootState, Gender | undefined>((state) => state.user.data.gender);

  useEffect(() => {
    if (transitionTask === "upsell") {
      dispatch({
        type: UPSELL_CASE,
        destination: destination,
        userGender: userGender ?? Gender.Other,
        chosenConditions: newCase.data.conditions,
      });
    }
  }, []);

  return (
    <div className="transition-content-wrapper">
      <img className="loader-img" src={Loader} />
      <Title as="h1" size="giant">
        Taking off 🛫
      </Title>
    </div>
  );
};
export default TransitionComponent;
