import * as Sentry from "@sentry/react";
import { SMARTY_EMBEDDED_KEY } from "@utils/constantsEnv";

export type AddressSuggestion = {
  street_line: string;
  secondary?: string;
  city: string;
  state: string;
  zipcode: string;
  full_address: string;
  entries?: number;
};

const useAddressSuggestion = async (query: string): Promise<AddressSuggestion[]> => {
  const url = `https://us-autocomplete-pro.api.smarty.com/lookup?key=${SMARTY_EMBEDDED_KEY}&search=${query}`;
  let suggestions: AddressSuggestion[] = [];
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const { suggestions: results } = await response.json();

    suggestions = results.map((address: AddressSuggestion) => {
      const { entries, secondary, ...cleanObject } = address;
      return {
        ...cleanObject,
        full_address: Object.values(cleanObject).join(" "),
      };
    });
  } catch (err) {
    Sentry.captureException(err);
  }

  return suggestions;
};

export default useAddressSuggestion;
